import Sentry from '$lib/sentry';

/** Return `true` if the browser is advanced enough to run Square Booking. */
export function browserIsSupported(): boolean {
	let result: boolean;
	try {
		result =
			// Ensure that Safari is advanced enough (only Safari 15.4+ supports this)
			CSS.supports('selector(:focus-visible)') &&
			// Ensure that Chrome is advanced enough (only Chrome 101+ and Safari 15.4+ support this)
			(CSS.supports('font-palette: initial') ||
				// OR... Ensure that Firefox is advanced enough (only Firefox 101+ supports `dvh` values)
				CSS.supports('(height: 100dvh)'));
	} catch (error) {
		Sentry.captureException(error);
		return false;
	}

	if (!result)
		Sentry.captureMessage('User Browser is not compatible with Square Booking.', {
			level: 'warning',
		});

	return result;
}
